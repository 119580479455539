<!-- eslint-disable no-undef -->
<template>
  <q-layout view="hHh lpR fff">
    <q-header style="background-color: var(--q-main); color: unset" :reveal="false" flat>
      <q-toolbar v-if="q.screen.gt.xs" class="flex justify-between"
        style="max-width: 1720px; width: 100%; margin-left: auto; margin-right: auto">
        <q-toolbar-title style="padding: 15px">
          <img :src="q.dark.isActive ? logoKITDark : logoKIT" alt="KIT Logo" style="width: 150px;" />
        </q-toolbar-title>
        <q-space class="gt-xs" />
        <div>
          <ul class="top-menu">
            <li v-if="isLoggedIn">{{ userProfile.firstName }} {{ userProfile.lastName }}</li>
            <li v-if="isLoggedIn">
              <q-btn flat dense size="sm" :icon="symOutlinedSettings" :to="{ name: 'UserSettings' }"
                :title="t('common.labels.settings')">
                <q-tooltip>{{ t("common.labels.settings") }}</q-tooltip>
              </q-btn>
              <q-btn flat dense size="sm" :icon="symOutlinedLogout" color="red-4" :title="t('common.labels.signout')"
                @click="logout"><q-tooltip>{{ t("common.labels.signout") }}</q-tooltip></q-btn>
            </li>
            <li v-if="!isLoggedIn">
              <q-btn-dropdown :label="t('common.terms.login')" :icon="symOutlinedLogin" color="primary" size="sm" dense>
                <q-list dense>
                  <q-item href="/api/user/oidc-login">
                    <q-item-section side>
                      <q-icon :name="symOutlinedVerifiedUser"></q-icon>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-body2">{{ t("common.terms.kitmember") }}</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item :to="{ name: 'Login' }">
                    <q-item-section side>
                      <q-icon :name="symOutlinedLocationAway"></q-icon>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-body2">{{ t("common.terms.externalusers") }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </li>
            <li>
              <LanguageToggleButton />
            </li>
          </ul>
          <nav>
            <ul>
              <li>
                <router-link to="/" title="Startseite"><q-icon size="24px" :name="matHome" /> </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'BzmOverview' }">BZM+ <q-icon :name="symOutlinedExpandMore"
                    size="sm"></q-icon></router-link>
                <q-list>
                  <q-item v-ripple :to="{ name: 'BzmOverview' }" clickable exact>
                    <q-item-section>
                      <q-item-label> {{ $t("bzmplus.menu.labels.overview") }} </q-item-label>
                    </q-item-section>
                  </q-item>
                  <template v-if="userHasRole('bzmUser')">
                    <q-item v-ripple :to="{ name: 'BzmProcedures' }" clickable>
                      <q-item-section>
                        <q-item-label> {{ $t("bzmplus.menu.labels.procedures") }} </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item v-ripple :to="{ name: 'BzmDossierDownloader' }" clickable exact>
                      <q-item-section>
                        <q-item-label> {{ $t("bzmplus.menu.labels.dossierdownloader") }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item v-ripple href="https://docs.campus.kit.edu/bzmplus/" clickable target="_blank">
                      <q-item-section>
                        <q-item-label> {{ t("common.labels.documentation") }} <q-icon
                            :name="symOutlinedOpenInNew"></q-icon> </q-item-label>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </li>
              <li>
                <router-link to="/signmeup"> SignMeUp <q-icon :name="symOutlinedExpandMore" size="sm"></q-icon>
                </router-link>
                <q-list>
                  <q-item v-ripple :to="{ name: 'SMUProceduresList' }" clickable>
                    <q-item-section>{{ t("signmeup.menu.labels.offering") }}</q-item-section>
                  </q-item>
                  <q-item v-ripple :to="{ name: 'SMURegistrationsList' }" clickable>
                    <q-item-section>
                      <q-item-label> {{ t("signmeup.menu.labels.myregistrations") }} </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item v-if="userHasRole('employee@kit.edu') || userHasRole('affiliate@kit.edu')" v-ripple
                    :to="{ name: 'SMUProceduresManage' }" clickable>
                    <q-item-section>{{ t("signmeup.menu.labels.manage") }}</q-item-section>
                  </q-item>

                  <q-item v-ripple href="https://docs.campus.kit.edu/signmeup/about.html" clickable target="_blank">
                    <q-item-section>
                      <q-item-label> {{ t("common.labels.documentation") }} <q-icon :name="symOutlinedOpenInNew"></q-icon>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </li>
              <li>
                <router-link to="/contact">{{ t("common.labels.contact") }}</router-link>
              </li>

              <li>
                <q-toggle color="blue-grey-8" size="lg" keep-color :model-value="preferences.isDarkMode"
                  aria-label="toggle dark mode" role="switch" dense
                  :icon="preferences.isDarkMode ? symOutlinedDarkMode : symOutlinedLightMode"
                  @update:model-value="preferences.setDarkMode">
                </q-toggle>
              </li>
              <li v-if="q.screen.gt.lg">
                <q-toggle color="blue-grey-8" size="lg" keep-color v-model="preferences.settings.fullWidthLayout" dense
                  aria-label="toggle full width layout" role="switch"
                  :icon="preferences.settings.fullWidthLayout ? symOutlinedWidthFull : symOutlinedWidthNormal">
                  <q-tooltip class="text-body2">{{ t('portal.usefullwidth') }}</q-tooltip>
                </q-toggle>
              </li>
            </ul>
          </nav>
        </div>
      </q-toolbar>

      <!-- 
        ### MOBILE TOOLBAR ###
      -->
      <q-toolbar v-else>
        <q-toolbar-title class="items-center flex">
          <img :src="q.dark.isActive ? logoKITDark : logoKIT" alt="KIT Logo" style="width: 150px;" />
        </q-toolbar-title>
        <q-space />
        <q-toggle color="blue-grey-8" size="lg" keep-color :model-value="preferences.isDarkMode"
          aria-label="toggle dark mode" role="switch"
          :icon="preferences.isDarkMode ? symOutlinedDarkMode : symOutlinedLightMode"
          @update:model-value="preferences.setDarkMode">
        </q-toggle>
        <LanguageToggleButton size="md" />
        <q-btn dense flat round :icon="symOutlinedMenu" size="lg" @click="toggleRightDrawer" />
      </q-toolbar>
    </q-header>
    <q-drawer v-model="rightDrawerOpen" behavior="mobile" side="right" bordered overlay class="q-pt-md">
      <q-list class="q-mb-md">
        <q-item>
          <q-item-section avatar>
            <CampusPlusLogo />
          </q-item-section>
          <q-item-section>
            <q-item-label class="text-h3"> Campus Plus </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <q-list class="q-mb-md">
        <q-item v-if="isLoggedIn">
          <q-item-section>
            <q-item-label caption>Angemeldet als:</q-item-label>
            <q-item-label>{{ userProfile.firstName }} {{ userProfile.lastName }}
              <q-btn flat dense size="sm" :icon="symOutlinedSettings" color="default" :to="{ name: 'Profile' }"
                class="q-ml-md">
                <q-tooltip>{{ t("common.labels.settings") }}</q-tooltip>
              </q-btn>
              <q-btn flat dense size="sm" :icon="symOutlinedLogout" color="red" class="q-ml-md"
                :title="$t('common.labels.signout')" @click="logout">
                ><q-tooltip>{{ t("common.labels.signout") }}</q-tooltip>
              </q-btn>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-else>
          <q-item-section>
            <q-item-label caption>{{ t("common.labels.signin") }}</q-item-label>
            <q-item-label>
              <q-btn dense size="md" :icon="symOutlinedLogin" color="teal" href="/api/user/oidc-login"
                :label="t('common.terms.kitmember')" class="q-mr-md" @click="logout"></q-btn>
              <q-btn dense size="md" :icon="symOutlinedLogin" color="purple" :to="{ name: 'Login' }"
                :label="t('common.terms.externalusers')" @click="logout"></q-btn>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <q-list class="cp-navbar" dense>
        <q-item to="/" clickable active-class="navbar-menu-active" exact class="q-mb-lg">
          <q-item-section avatar>
            <q-icon :name="symOutlinedHome" />
          </q-item-section>
          <q-item-section>
            <q-item-label> {{ t("common.labels.home") }} </q-item-label>
          </q-item-section>
        </q-item>

        <!-- BZM+ -->
        <q-expansion-item v-if="userHasRole('bzmUser')" :icon="symOutlinedHotelClass" label="BZM+"
          caption="Bewerberauswahl" dark class="q-mb-lg" :content-inset-level="1"
          :model-value="$route.path.startsWith('/bzmplus')" active-class="navbar-menu-group-active">
          <q-item v-ripple :to="{ name: 'BzmOverview' }" clickable active-class="navbar-menu-active" exact>
            <q-item-section>
              <q-item-label> {{ $t("bzmplus.menu.labels.overview") }} </q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-ripple :to="{ name: 'BzmProcedures' }" clickable active-class="navbar-menu-active">
            <q-item-section>
              <q-item-label> {{ $t("bzmplus.menu.labels.procedures") }} </q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-ripple :to="{ name: 'BzmDossierDownloader' }" clickable active-class="navbar-menu-active" exact>
            <q-item-section>
              <q-item-label> {{ $t("bzmplus.menu.labels.dossierdownloader") }} </q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-ripple href="https://docs.campus.kit.edu/bzmplus/" clickable active-class="navbar-menu-active"
            target="_blank">
            <q-item-section>
              <q-item-label> {{ t("common.labels.documentation") }} <q-icon :name="symOutlinedOpenInNew"></q-icon>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <!-- SignMeUp -->
        <q-expansion-item :icon="symOutlinedAppRegistration" label="SignMeUp" caption="Anmeldeverfahren" dark
          class="q-mb-lg" :content-inset-level="1" :model-value="$route.path.startsWith('/signmeup')"
          active-class="navbar-menu-group-active">
          <q-item v-ripple :to="{ name: 'SMUProceduresList' }" clickable active-class="navbar-menu-active">
            <q-item-section>
              <q-item-label> {{ t("signmeup.menu.labels.offering") }} </q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-if="userHasRole('employee@kit.edu') || userHasRole('affiliate@kit.edu')" v-ripple
            :to="{ name: 'SMUProceduresManage' }" clickable active-class="navbar-menu-active">
            <q-item-section>
              <q-item-label> {{ t("signmeup.menu.labels.manage") }} </q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-ripple :to="{ name: 'SMURegistrationsList' }" clickable active-class="navbar-menu-active">
            <q-item-section>
              <q-item-label> {{ t("signmeup.menu.labels.myregistrations") }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-ripple href="https://docs.campus.kit.edu/signmeup/about.html" clickable
            active-class="navbar-menu-active" target="_blank">
            <q-item-section>
              <q-item-label> {{ t("common.labels.documentation") }} <q-icon :name="symOutlinedOpenInNew"></q-icon>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-item v-ripple :to="{ name: 'Contact' }" clickable active-class="navbar-menu-active">
          <q-item-section avatar>
            <q-icon :name="symOutlinedContactSupport" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ t("common.labels.contact") }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <KITPageHeaderFrame>
        <router-view />
      </KITPageHeaderFrame>
    </q-page-container>
    <q-footer dark :class="q.dark.isActive ? 'q-dark' : 'bg-grey-9 text-white'">
      <div class="content-wrap flex justify-between">
        <div>
          Campus+ Portal v{{ version }} <span v-if="versiondate">({{ d(versiondate, "dateonly") }})</span>
          <a href="https://docs.campus.kit.edu/updates/" target="_blank" class="q-ml-sm">{{ t("common.labels.changes")
          }}</a>
        </div>
        <div></div>
      </div>
    </q-footer>
  </q-layout>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import LanguageToggleButton from "@/components/base/LanguageToggleButton.vue";
import CampusPlusLogo from "@/components/shared/CampusPlusLogo.vue";
import useUserProfile from "@/composables/useUserProfile";
import KITPageHeaderFrame from "@/layouts/KITPageHeaderFrame.vue";
import userService from "@/services/userService";
import { usePreferencesStore } from "@/stores/preferences";
import { matHome } from "@quasar/extras/material-icons";
import {
  symOutlinedAppRegistration,
  symOutlinedContactSupport,
  symOutlinedDarkMode,
  symOutlinedExpandMore,
  symOutlinedHome,
  symOutlinedHotelClass,
  symOutlinedLightMode,
  symOutlinedLocationAway,
  symOutlinedLogin,
  symOutlinedLogout,
  symOutlinedMenu,
  symOutlinedOpenInNew,
  symOutlinedSettings,
  symOutlinedVerifiedUser,
  symOutlinedWidthFull,
  symOutlinedWidthNormal
} from "@quasar/extras/material-symbols-outlined";
import { useTitle } from "@vueuse/core";
import logoKIT from "@/assets/Logo_KIT.svg";
import logoKITDark from "@/assets/Logo_KIT_dark.svg";

const q = useQuasar();
const preferences = usePreferencesStore();
useTitle("Campus Plus Portal", { observe: true });
const { t, d } = useI18n();
// eslint-disable-next-line no-undef
const version = computed(() => __APP_VERSION__);
// eslint-disable-next-line no-undef
const versiondate = computed(() => __APP_VERSION_DATE__);

const rightDrawerOpen = ref(false);

const { userProfile, isLoggedIn, fetchProfile, userHasRole } = useUserProfile();
const router = useRouter();
const logout = () => {
  userService.logout().then((data) => {
    fetchProfile().then(() => {
      router.push({ name: "Home" });
    });
    if (data && data.data) {
      q.dialog({
        dark: true,
        title: t("portal.logout.fullylogout"),
        html: true,
        message: t("portal.logout.fullylogouttext"),
        cancel: true,
      }).onOk(() => {
        window.location = data.data;
      });
    }
  });
};
const toggleRightDrawer = () => (rightDrawerOpen.value = !rightDrawerOpen.value);

onMounted(() => {
  q.dark.set(preferences.isDarkMode);
});
</script>

<style lang="scss">
.content-wrap {
  max-width: 1720px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  background: none !important;

  body.screen--xs & {
    padding: 15px 5px;
  }
}

nav {
  ul {
    display: flex;
    justify-items: end;
    align-items: center;
    flex-wrap: wrap;

    li {
      display: inline-block;
      margin: 0 1rem;

      a {
        font-weight: bold;
        color: #404040 !important;
        text-decoration: none;
        font-size: 1rem;

        &.router-link-active {
          border-bottom: 5px solid #00876c;
        }

        .q-item__section {
          font-size: 0.875rem;
        }

        &:hover {
          color: #00876c !important;
        }

        .body--dark & {
          color: #e6e6e6 !important;
        }
      }

      div.q-list {
        display: none;
        position: absolute;
        z-index: 1;

        background-color: var(--q-content);
        border-bottom: 3px solid #00876c;
      }

      &:hover div.q-list {
        display: block;
      }
    }
  }
}

.top-menu {
  display: flex;
  justify-content: end;
  list-style: none;
  align-items: center;
  padding: 0 15px;

  li {
    padding: 0 5px;
  }
}
</style>
