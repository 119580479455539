<template>
  <KITLayout></KITLayout>
  <q-banner v-if="!online" class="bg-red text-white"
    style="position: absolute; width: 500px; left: calc(50% - 250px); bottom: 0; z-index: 9999"><template #avatar> <q-icon
        :name="symOutlinedSignalDisconnected" color="white" size="md" /> </template>Sie sind derzeit offline.
    Änderungen können nicht gespeichert werden.</q-banner>
  <!-- <MaterialLayout v-if="preferences.settings?.theme == 'Material'"></MaterialLayout> -->
</template>
<script setup>
import { onBeforeMount } from "vue";
import { useQuasar } from "quasar";
import quasarLangDe from "quasar/lang/de";
import quasarLangEn from "quasar/lang/en-US";
import { useI18n } from "vue-i18n";

import KITLayout from "@/layouts/KIT.vue";
import { usePreferencesStore } from "@/stores/preferences.js";
import { symOutlinedSignalDisconnected } from "@quasar/extras/material-symbols-outlined";
import { useOnline } from "@vueuse/core";
const preferences = usePreferencesStore();
if (!preferences.settings?.theme) {
  preferences.settings.theme = "KIT";
}

const { locale } = useI18n({ useScope: "global" });
const $q = useQuasar();

//$q.addressbarColor.set("#00876C");

const online = useOnline();

onBeforeMount(() => {
  if (preferences.settings?.locale) {
    locale.value = preferences.settings.locale;
    if (locale.value === "de") {
      $q.lang.set(quasarLangDe);
    } else {
      $q.lang.set(quasarLangEn);
    }
  }
});
</script>

<style lang="scss">
@import "@/custom.scss";
</style>
