import { RouterView } from "vue-router";

const bzmRoutes = [
  {
    path: "/bzmplus",
    name: "BzmPlus",
    component: () => import("@/features/bzmplus/components/Index.vue"),
    meta: {
      title: "BZM+",
      breadCrumb: "BZM+",
      //navlogo: BzmPlusLogo,
    },
    children: [
      {
        path: "",
        name: "BzmOverview",
        component: () => import("@/features/bzmplus/components/Overview.vue"),
        meta: {
          title: "Übersicht",
        },
      },
      {
        path: "downloader",
        name: "BzmDossierDownloader",
        component: () => import("@/features/bzmplus/components/DossierDownloader.vue"),
        meta: {
          title: "Sammelakten-Downloader",
          breadCrumb: "Sammelakten-Downloader",
          requiresRole: "bzmUser",
        },
      },
      {
        path: "applications",
        component: RouterView,
        meta: {
          breadCrumb: "Bewerbungen (studienangebotsübergreifend)",
          requires2FA: true,
          requiresRole: "bzmUser",
        },
        children: [
          {
            path: ":appid",
            name: "BzmGlobalApplicationDetails",
            component: () => import("@/features/bzmplus/components/ApplicationDetails/Index.vue"),
            meta: {
              title: "Bewerbung",
              breadCrumb: "Bewerbungsdetails",
            },
            props: true,

            children: [
              {
                path: "",
                name: "BzmGlobalApplicationDetailsOverview",
                component: () => import("@/features/bzmplus/components/ApplicationDetails/ApplicationOverview.vue"),
              },
              {
                path: "rating",
                name: "BzmGlobalApplicationDetailsRating",
                component: () => import("@/features/bzmplus/components/ApplicationDetails/ApplicationRating.vue"),
                meta: {
                  title: "Bewertung",
                  breadCrumb: "Bewertung",
                },
              },
            ],
          },
        ],
      },
      {
        path: "procedures",
        component: RouterView,
        meta: {
          title: "Studienangebote",
          breadCrumb: "Studienangebote",
          requires2FA: true,
          requiresRole: "bzmUser",
        },
        children: [
          {
            path: "",
            name: "BzmProcedures",
            component: () => import("@/features/bzmplus/components/Procedures.vue"),
            meta: {
              title: "Studienangebote",
            },
          },
          {
            path: ":id",
            component: () => import("@/features/bzmplus/components/ProcedureDetails/Index.vue"),
            meta: {
              title: "Studienangebot",
              breadCrumb: "Detailansicht",
            },
            children: [
              {
                path: "",
                name: "BzmProcedureDetailsDashboard",
                component: () => import("@/features/bzmplus/components/ProcedureDetails/ProcedureDashboard.vue"),
                meta: {
                  title: "Dashboard",
                  breadCrumb: "Übersicht",
                },
              },
              {
                path: "applications",
                component: RouterView,
                meta: {
                  title: "Bewerbungen",
                  breadCrumb: "Bewerbungen",
                },
                children: [
                  {
                    path: "",
                    name: "BzmProcedureDetailsApplications",
                    component: () => import("@/features/bzmplus/components/ProcedureDetails/ProcedureApplications.vue"),
                    meta: {
                      title: "Bewerbungen",
                      breadCrumb: "Übersicht",
                    },
                  },
                  {
                    path: ":appid/:listid?",
                    name: "BzmApplicationDetails",
                    component: () => import("@/features/bzmplus/components/ApplicationDetails/Index.vue"),
                    meta: {
                      title: "Bewerbung",
                      breadCrumb: "Bewerbungsdetails",
                    },
                    props: true,
                    children: [
                      {
                        path: "",
                        name: "BzmApplicationDetailsOverview",
                        component: () => import("@/features/bzmplus/components/ApplicationDetails/ApplicationOverview.vue"),
                      },
                      {
                        path: "rating",
                        name: "BzmApplicationDetailsRating",
                        component: () => import("@/features/bzmplus/components/ApplicationDetails/ApplicationRating.vue"),
                        meta: {
                          title: "Bewertung",
                          breadCrumb: "Bewertung",
                        },
                      },
                    ],
                  },
                ],
              },

              {
                path: "import",
                component: RouterView,
                meta: {
                  title: "Import von Bewertungen",
                  breadCrumb: "Import",
                },
                children: [
                  {
                    path: "",
                    name: "BzmProcedureDetailsImport",
                    component: () => import("@/features/bzmplus/components/ProcedureDetails/ProcedureImport.vue"),
                  },
                  {
                    path: ":importid",
                    name: "BzmProcedureDetailsImportView",
                    component: () => import("@/features/bzmplus/components/ProcedureDetails/ProcedureImportView.vue"),
                    meta: {
                      title: "Kontrollansicht",
                      breadCrumb: "Kontrollansicht",
                    },
                  },
                ],
              },
              {
                path: "statistics",
                name: "BzmProcedureDetailsStatistics",
                component: () => import("@/features/bzmplus/components/ProcedureDetails/ProcedureStats.vue"),
                meta: {
                  title: "Auswertungen",
                  breadCrumb: "Auswertungen",
                },
              },
            ],
          },
        ],
      },
      {
        path: "groups/:id",
        component: () => import("@/features/bzmplus/components/GroupDetails/Index.vue"),
        meta: {
          title: "Gruppe",
          breadCrumb: "Gruppe",
          requires2FA: true,
          requiresRole: "bzmUser",
        },
        children: [
          {
            path: "",
            name: "BzmGroupDetails",
            component: () => import("@/features/bzmplus/components/GroupDetails/GroupDashboard.vue"),
            meta: {
              title: "Übersicht",
              breadCrumb: "Übersicht",
            },
          },
          {
            path: "applications",
            component: RouterView,
            meta: {
              title: "Bewerbungen",
              breadCrumb: "Bewerbungen",
            },
            children: [
              {
                path: "",
                name: "BzmGroupApplications",
                component: () => import("@/features/bzmplus/components/GroupDetails/GroupApplications.vue"),
                meta: {
                  title: "Bewerbungen",
                  breadCrumb: "Übersicht",
                },
              },
              {
                path: ":appid/:listid?",
                name: "BzmGroupApplicationDetails",
                component: () => import("@/features/bzmplus/components/ApplicationDetails/Index.vue"),
                meta: {
                  title: "Bewerbung",
                  breadCrumb: "Bewerbungsdetails",
                },
                props: true,

                children: [
                  {
                    path: "",
                    name: "BzmGroupApplicationDetailsOverview",
                    component: () => import("@/features/bzmplus/components/ApplicationDetails/ApplicationOverview.vue"),
                  },
                  {
                    path: "rating",
                    name: "BzmGroupApplicationDetailsRating",
                    component: () => import("@/features/bzmplus/components/ApplicationDetails/ApplicationRating.vue"),
                    meta: {
                      title: "Bewertung",
                      breadCrumb: "Bewertung",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "stats",
            name: "BzmGroupStats",
            component: () => import("@/features/bzmplus/components/GroupDetails/GroupStats.vue"),
            meta: {
              title: "Bewerbungen",
              breadCrumb: "Bewerbungen",
            },
          },
        ],
      },
    ],
  },
];

export default bzmRoutes;
