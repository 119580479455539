const signMeUpRoutes = [
  {
    path: "/signmeup",
    component: () => import("@/features/signmeup/MetaFrame.vue"),
    name: "SMUStart",
    meta: {
      requiresLoggedIn: false,
      title: "SignMeUp",
      breadCrumb: "SignMeUp",
      //navlogo: BzmPlusLogo,
    },
    children: [
      {
        path: "",
        name: "SmuRedirect",
        redirect: { name: "SMUProceduresList" },
      },
      {
        path: "go/:id([a-zA-Z0-9]+)",
        component: () => import("@/features/signmeup/views/RegistrationAdd.vue"),
        meta: {
          breadCrumb: "PROCEDURENAME",
        },
      },
      {
        path: "campusvvz",
        name: "SMUCampusVVZ",
        component: () => import("@/features/signmeup/views/CampusVvzTree.vue"),
        meta: {
          requiresLoggedIn: true,
          breadCrumb: "Campus VVZ Baum",
        },
      },
      {
        path: "procedures",
        children: [
          {
            path: ":term([a-zA-Z0-9]{6})?/:heading([A-Z]+)?/:group?",
            name: "SMUProceduresList",
            component: () => import("@/features/signmeup/views/ProceduresList.vue"),
            meta: {
              title: "Liste",
              breadCrumb: "Angebot",
            },
          },
          {
            path: ":id([0-9]+)",
            name: "SMUProcedureView",
            component: () => import("@/features/signmeup/views/ProcedureView.vue"),
            meta: {
              breadCrumb: "PROCEDURENAME",
            },
          },
          {
            path: ":id([0-9]+)/register",
            name: "SMUProcedureRegister",
            component: () => import("@/features/signmeup/views/RegistrationAdd.vue"),
            meta: {
              requiresLoggedIn: true,
              breadCrumb: "PROCEDURENAME",
            },
          },
        ],
      },
      {
        path: "manage",
        name: "SMUManage",
        meta: {
          breadCrumb: "Verwalten",
          requiresLoggedIn: true,
        },
        children: [
          {
            path: "",
            name: "SMUManageRedirect",
            redirect: { name: "SMUProceduresManage" },
          },
          {
            path: "create",
            redirect: (to) => {
              // the function receives the target route as the argument
              // we return a redirect path/location here.
              return { name: "SMUProceduresManage", query: { campusEventGuid: to.query.campusEventGuid, term: to.query.term } };
            },
          },
          {
            path: "procedures",
            children: [
              {
                path: "",
                name: "SMUProceduresManage",
                component: () => import("@/features/signmeup/views/ProceduresManage.vue"),
                meta: {
                  title: "Eigene Verfahren",
                  breadCrumb: "Eigene Verfahren",
                },
              },
              {
                path: "import",
                name: "SMUProceduresImport",
                component: () => import("@/features/signmeup/views/ProceduresImport.vue"),
                meta: {
                  title: "Importieren von Verfahren",
                  breadCrumb: "Importieren von Verfahren",
                },
              },
              {
                path: ":id",
                component: () => import("@/features/signmeup/views/ProcedureFrame.vue"),
                meta: {
                  breadCrumb: "PROCEDURENAME",
                },
                children: [
                  {
                    path: "",
                    name: "SMUProcedureManage",
                    component: () => import("@/features/signmeup/views/ProcedureManage.vue"),
                  },

                  {
                    path: "editnew",
                    name: "SMUProcedureEditAll",
                    component: () => import("@/features/signmeup/views/ProcedureEditAll.vue"),
                    meta: {
                      breadCrumb: "PROCEDURENAME",
                    },
                  },
                  {
                    path: "registrations",
                    name: "SMUProcedureRegistrations",
                    component: () => import("@/features/signmeup/views/ProcedureRegistrations.vue"),
                    meta: {
                      breadCrumb: "Anmeldeliste",
                    },
                  },
                  {
                    path: "export",
                    name: "SMUProcedureExport",
                    component: () => import("@/features/signmeup/views/ProcedureExport.vue"),
                    meta: {
                      breadCrumb: "Export",
                    },
                  },
                  {
                    path: "exportilias",
                    name: "SMUProcedureExportIlias",
                    component: () => import("@/features/signmeup/views/ProcedureExportIlias.vue"),
                    meta: {
                      breadCrumb: "ILIAS Export",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "registrations",
        meta: {
          requiresLoggedIn: true,
        },
        children: [
          {
            path: "",
            name: "SMURegistrationsList",
            component: () => import("@/features/signmeup/views/RegistrationsList.vue"),
            meta: {
              title: "Meine Anmeldungen",
              breadCrumb: "Meine Anmeldungen",
            },
          },
          {
            path: "join/:id([a-zA-Z0-9]+)",
            name: "SMURegistrationJoin",
            component: () => import("@/features/signmeup/views/RegistrationJoin.vue"),
            meta: {
              title: "Lerngruppe beitreten",
              breadCrumb: "Lerngruppe beitreten",
            },
          },
          {
            path: ":id([0-9]+)",
            meta: {
              breadCrumb: "PROCEDURENAME",
            },
            children: [
              {
                path: "",
                name: "SMURegistrationView",
                component: () => import("@/features/signmeup/views/RegistrationView.vue"),
                meta: {
                  title: "Anmeldung ansehen",
                  breadCrumb: "Anmeldung ansehen",
                },
              },
              {
                path: "edit",
                name: "SMURegistrationEdit",
                component: () => import("@/features/signmeup/views/RegistrationEdit.vue"),
                meta: {
                  title: "Bearbeiten",
                  breadCrumb: "Bearbeiten",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default signMeUpRoutes;
