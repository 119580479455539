<template>
  <q-select
    ref="searchField"
    v-model="selectedApp"
    filled
    use-input
    dense
    placeholder="Suchen"
    input-debounce="300"
    hide-dropdown-icon
    :options="appList"
    hide-selected
    clearable
    dark
    style="width: 250px"
    @filter="findApplications"
    @update:model-value="(app) => openApplication(app)"
  >
    <template #prepend>
      <q-icon :name="matPersonSearch"></q-icon>
    </template>
    <template #no-option>
      <q-item>
        <q-item-section class="text-grey"> Keine Treffer </q-item-section>
      </q-item>
    </template>
    <template #option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section>
          <q-item-label>[{{ scope.opt.number }}] {{ scope.opt.lastName }}, {{ scope.opt.firstName }}</q-item-label>
          <q-item-label caption>{{ scope.opt.applicantNumber }} | {{ scope.opt.procedureName }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import { matPersonSearch } from "@quasar/extras/material-icons";

import bzmService from "../../services/bzmService";

const selectedApp = ref(null);
const searchField = ref(null);
const appList = ref(null);
const router = useRouter();

const openApplication = (app) => {
  if (app) {
    selectedApp.value = null;
    router.push({
      name: "BzmApplicationDetailsOverview",
      params: { appid: app.guid, id: app.cdapGuid },
    });
  }
};

const findApplications = (val, update, abort) => {
  if (val.length < 4) {
    abort();
    return;
  }
  bzmService.searchApplications(val).then((res) => {
    appList.value = res;
    update();
  });
};
</script>
