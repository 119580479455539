<template>
  <q-btn flat dense size="sm" :icon="symOutlinedLanguage" @click="toggleLanguage"
    >{{ locale == "de" ? "EN" : "DE" }}<q-tooltip>{{ t("common.labels.switchlanguage") }}</q-tooltip></q-btn
  >
</template>

<script setup>
import { useQuasar } from "quasar";
import quasarLangDe from "quasar/lang/de";
import quasarLangEn from "quasar/lang/en-US";
import { useI18n } from "vue-i18n";

import { usePreferencesStore } from "@/stores/preferences.js";
import { symOutlinedLanguage } from "@quasar/extras/material-symbols-outlined";

const { t, locale } = useI18n({ useScope: "global" });
const $q = useQuasar();
const preferences = usePreferencesStore();

const toggleLanguage = () => {
  if (locale.value === "de") {
    locale.value = "en";
    $q.lang.set(quasarLangEn);
  } else {
    locale.value = "de";
    $q.lang.set(quasarLangDe);
  }
  preferences.setLocale(locale.value);
};
</script>
