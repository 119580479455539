import axios from "axios";
axios.defaults.baseURL = "/api/";

export default {
  async getInfo() {
    const response = await axios.get("user/info");
    return response.data;
  },
  async register(user) {
    const response = await axios.post("user/register", user);
    return response.data;
  },
  async confirm(userId, code) {
    const response = await axios.post("user/confirm", { userId, code });
    return response.data;
  },
  async login(email, password) {
    const response = await axios.post("user/login", { email, password });
    return response.data;
  },
  async getKitProducts() {
    const response = await axios.get("meta/products");
    return response.data;
  },
  async forgotPassword(email) {
    const response = await axios.post("user/forgot-password", { email });
    return response.data;
  },
  async resetPassword(email, password, code) {
    const response = await axios.post("user/reset-password", { email, password, code });
    return response.data;
  },
  async getUserTokens() {
    const response = await axios.get("user/tokens");
    return response.data;
  },
  async deleteUserToken(tokenId) {
    const response = await axios.delete("user/token/" + tokenId);
    return response.data;
  },
  async createUserToken(tokenSettings) {
    const response = await axios.post("user/token", tokenSettings);
    return response.data;
  },
  logout() {
    return axios.post("user/logout");
  },
};
