{
  "confirm": {
    "error": "Bei der Bestätigung ist ein unbekannter Fehler aufgetreten.",
    "failed": "Bestätigung Ihres Accounts fehlgeschlagen",
    "invalidtoken": "Der Bestätigungslink ist ungültig.",
    "notfound": "Der im Bestätigungslink angegebene Benutzer konnte nicht gefunden werden.",
    "success": "Ihr Zugang wurde bestätigt. Sie können sich nun anmelden.",
    "title": "Bestätigung Ihrer Registrierung"
  },
  "contact": {
    "doculink": "Übrigens: Kennung Sie schon unsere {0}? Hier finden Sie die grundlegenden Funktionen des Portals erklärt.",
    "intro": "Gerne helfen wir Ihnen bei Fragen oder Problemen mit dem Campus+ Portal weiter. Ebenso freuen wir uns über Ihr Feedback und Verbesserungsvorschläge.",
    "pleasewrite": "Schreiben Sie uns einfach eine E-Mail an unsere allgemeine Support-Adresse:",
    "title": "Kontakt aufnehmen"
  },
  "errorpages": {
    "401": {
      "body": "Für den Zugriff auf diese Seite ist eine Anmeldung erforderlich.",
      "pleaselogin": "Bitte melden Sie sich über einen der folgenden Wege an:",
      "title": "Anmeldung erforderlich"
    },
    "401token": {
      "2falogin": "2FA Anmeldung",
      "body": "Für den Zugriff auf diese Funktion ist eine 2-Faktor-Authentifizierung erforderlich.",
      "pleaselogin": "Bitte melden Sie sich mit 2-Faktor-Authentifizierung und Token erneut an:",
      "title": "2-Faktor-Authentifizierung erforderlich"
    },
    "403": {
      "body": "Sie besitzen leider keinen Zugriff auf diese Seite.",
      "title": "Zugriff verweigert"
    },
    "404": {
      "body": "Die gewünschte Seite konnte nicht gefunden werden.",
      "title": "Seite nicht gefunden"
    }
  },
  "gotosignmeup": "Zum Veranstaltungsangebot",
  "login": {
    "error": "Bei der Anmeldung ist ein allgemeiner Fehler aufgetreten, bitte wenden Sie sich an den Support.",
    "forgotpassword": "Passwort vergessen?",
    "gotoregistration": "Hier registrieren",
    "intro": "Hier können Sie sich als externer, registrierter Nutzer anmelden.",
    "invalidcredentials": "Die eingegebenen Zugangsdaten sind ungültig",
    "locked": "Der angegebene Zugang ist derzeit gesperrt. Bitte wenden Sie sich an den Support.",
    "notconfirmed": "Die E-Mail-Adresse des angegebenen Accounts wurde noch nicht bestätigt. Eine Anmeldung ist erst nach Bestätigung möglich.",
    "notregistered": "Noch nicht registriert?",
    "success": "Anmeldung erfolgreich",
    "title": "Anmeldung für Externe Nutzer"
  },
  "logout": {
    "fullylogout": "Vollständig abmelden",
    "fullylogouttext": "Sie haben im Campus+ Portal erfolgreich abgemeldet, sind aber im KIT-weiten Single Sign On Dienst noch angemeldet. Möchten Sie sich auch dort abmelden?"
  },
  "notloggedin": "Nicht angemeldet",
  "notloggedtext": "Sie sind nicht angemeldet oder Ihre Sitzung ist ausgelaufen.",
  "passwordreset": {
    "backtologin": "Zurück zur Anmeldung",
    "errors": {
      "EmailInvalid": "Die angegebene E-Mail-Adresse ist ungültig",
      "InvalidToken": "Das Passwort zurücksetzen-Token ist ungültig oder wurde bereits benutzt.",
      "PasswordNotEmpty": "Das Passwort darf nicht leer sein",
      "PasswordRequiresDigit": "Das Passwort muss mindestens eine Ziffer enthalten",
      "PasswordRequiresLower": "Das Passwort muss mindestens einen Kleinbuchstaben enthalten",
      "PasswordRequiresUpper": "Das Passwort muss mindestens einen Großbuchstaben enthalten",
      "PasswordTooShort": "Das Passwort muss mindestens 6 Zeichen lang sein"
    },
    "form": "Neues Passwort festlegen",
    "intro": "Möchten Sie Ihr Passwort für Ihren externen Account zurücksetzen? Bitte beachten Sie: Dieses Formular ist ausschließlich extern registrierte Accounts und kann nicht für KIT-Konten genutzt werden.",
    "performintro": "Auf dieser Seite können Sie Ihr Passwort neu setzen, wenn Sie das bisherige vergessen haben.",
    "requestdetails": "Sofern die angegebene E-Mail-Adresse in unserer Benutzerdatenbank existiert, erhalten Sie in Kürze eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts.",
    "requestsent": "Ihre Anforderung zum Zurücksetzen des Passworts wurde erfolgreich an uns übermittelt.",
    "requestsuccess": "Passwort zurücksetzen angefordert",
    "submit": "Passwort zurücksetzen",
    "success": "Sie haben Ihr Passwort erfolgreich geändert und können sich nun mit dem neuen Passwort anmelden.",
    "title": "Passwort für externe Accounts zurücksetzen"
  },
  "pleaselogintoaccess": "Bitte melden Sie sich an, um auf diese Seite zuzugreifen.",
  "register": {
    "account": "Benutzerkonto",
    "buttons": {
      "gotologin": "Zur Anmeldung",
      "register": "Registrieren"
    },
    "errors": {
      "DuplicateEmail": "Die E-Mail-Adresse ist bereits in Verwendung",
      "DuplicateUserName": "Der Benutzername ist bereits vergeben",
      "EmailInvalid": "Die angegebene E-Mail-Adresse ist ungültig",
      "EmailNotEmpty": "Die E-Mail-Adresse darf nicht leer sein",
      "PasswordNotEmpty": "Das Passwort darf nicht leer sein",
      "PasswordRequiresDigit": "Das Passwort muss mindestens eine Ziffer enthalten",
      "PasswordRequiresLower": "Das Passwort muss mindestens einen Kleinbuchstaben enthalten",
      "PasswordRequiresUpper": "Das Passwort muss mindestens einen Großbuchstaben enthalten",
      "PasswordTooShort": "Das Passwort muss mindestens 6 Zeichen lang sein",
      "RecaptchaInvalid": "Die reCAPTCHA-Validierung ist fehlgeschlagen",
      "RecaptchaUntrusted": "Die reCAPTCHA-Validierung ist fehlgeschlagen"
    },
    "failed": "Registrierung nicht möglich:",
    "fields": {
      "currentunit": "Derzeitige Einrichtung (Hochschule oder Unternehmen) (optional)",
      "email": "E-Mail-Adresse",
      "firstname": "Vorname",
      "lastname": "Nachname",
      "password": "Passwort",
      "plannedcourse": "Geplanter Studiengang am KIT (optional)",
      "plannedcoursetooltip": "Die Anmeldung zu einigen Veranstaltungen ist auf Studiengänge des KIT beschränkt. Sind Sie gerade dabei an das KIT zu wechseln, besitzen jedoch noch keinen KIT-Zugang, können Sie sich durch Auswahl des geplanten Studiengangs dennoch zu diesen Veranstaltungen anmelden."
    },
    "intro": "Sie können sich als externer Nutzer in Campus+ registrieren, um Zugriff auf einen Teil der Funktionalitäten (z.B. Anmeldeverfahren) zu erhalten.",
    "iuk": "Ordnung für die digitale Informationsverarbeitung und Kommunikation (IuK)",
    "iuktext": "Ich stimme zu, dass die Nutzung dieses Angebots unter Einhaltung der {0} erfolgt.",
    "kitlogin": "Mit KIT-Account anmelden",
    "kitusernotice": "Angehörige des KIT (Mitarbeitende, Studierende, Partner) melden sich stattdessen über {0} an.",
    "profile": "Profil",
    "success": "Sie haben sich erfolgreich registriert! Bitte prüfen Sie Ihre E-Mails auf eine Bestätigungsmail.",
    "title": "Registrierung für externe Nutzer"
  },
  "start": {
    "changes": {
      "allchangestext": "Alle Neuerungen und Änderungen finden Sie in den",
      "versinfo": "Versionsinformationen"
    },
    "labels": {
      "newsletter": "Newsletter"
    },
    "login": {
      "loginExternal": "Anmelden (Externe)",
      "loginKIT": "Anmelden (KIT)",
      "register": "Registrieren (Externe)",
      "requiredText": "Für die meisten Funktionen müssen Sie sich anmelden."
    },
    "newsletter": {
      "body": "Wenn Sie regelmäßig über neue Funktionen und wichtige Änderungen informiert werden möchte, würden wir uns freuen, wenn Sie unsere {0} abonnieren.",
      "name": "Campus+ Mailingliste"
    },
    "welcome": {
      "body": "In diesem Portal möchten wir Ihnen innovative Funktionen rund um Studium und Lehre anbieten. Die Funktionen erweitern in vielen Fällen, die durch das Campus Management System (CAS) oder Bewerbungssystem (BZM) gebotenen Möglichkeiten. Das Portal startete im November 2021 mit dem Modul BZM+ mit vielen Features rund um das Bewerbungsmanagement. Im August 2022 kam das neue Feature \"Anmeldeverfahren\" hinzu.",
      "heading": "Herzlich Willkommen im Campus Plus Portal!",
      "label": "Willkommen"
    }
  },
  "tour": {
    "tooltip": "Durch Klick auf diesen Button erhalten Sie eine kleine Tour über die Funktionen der aktuellen Seite."
  },
  "usefullwidth": "Gesamte Breite des Browsers nutzen",
  "usersettings": {
    "errors": {
      "tokencreate": "Es ist ein Fehler beim Erstellen des Tokens aufgetreten. Stellen Sie sicher, dass Sie noch angemeldet sind."
    },
    "intro": "Auf dieser Seite können Sie zukünftig Einstellungen zu Ihrem Benutzerkonto vornehmen.<br />Derzeit steht nur (für berechtigte Personen) die Möglichkeit zur Verfügung, API Zugriffstokens zu stellen.",
    "title": "Einstellungen",
    "tokencopied": "Das Token wurde in die Zwischenablage kopiert",
    "tokencopy": "Token in die Zwischenablage kopieren",
    "tokencreate": "Neues Token erstellen",
    "tokencreateconfirm": "Bitte beachten Sie: Ein Token ist gleichgestellt mit Ihrem Benutzeraccount für die Verwendung im Campus+ Portal. Sie sind für alle Aktionen, die über ein von Ihnen generiertes Token durchgeführt werden, voll verantwortlich. Sofern Sie das Token weitergeben, kann eine fremde Person in Ihrem Namen (und Ihrer Verantwortung) Aktionen im Portal durchführen.",
    "tokencreated": "Ein neues Token wurde angelegt",
    "tokendelete": "Token löschen",
    "tokenintro": "Auf dieser Seite können Sie Zugriffstokens für den Zugriff auf unsere öffentliche API erstellen. Mit diesen authentifizieren Sie sich gegenüber dem Portal für den programmatischen Zugriff.<br /> <strong>Achtung!</strong> Die Tokens haben auf viele Bereiche die gleichen Zugriffsrechte wie Sie selbst! Wenn sie das Token weitergeben, kann eine weitere Person mit diesem Token Aktionen in Ihrem Namen durchführen.",
    "tokenlist": "Ihre API-Tokens"
  }
}
