{
  "confirm": {
    "error": "An unknown error occurred during validation.",
    "failed": "Your account confirmation failed",
    "invalidtoken": "The confirmation link is invalid.",
    "notfound": "The user specified in the confirmation link could not be found.",
    "success": "Your account has been confirmed. \nYou can log in now.",
    "title": "Confirmation of your registration"
  },
  "contact": {
    "doculink": "By the way: Do you already know our {0}? \nHere you will find the basic functions of the portal explained (currently only available in German).",
    "intro": "We would be happy to help you if you have any questions or problems with the Campus Portal. \nWe also look forward to your feedback and suggestions for improvement.",
    "pleasewrite": "Just send us an email to our general support address:",
    "title": "Contact Us"
  },
  "errorpages": {
    "401": {
      "body": "You need to be logged in to access this page.",
      "pleaselogin": "Please login using one of the following methods:",
      "title": "Login required"
    },
    "401token": {
      "2falogin": "2FA Login",
      "body": "Two-factor authentication is required to access this feature.",
      "pleaselogin": "Please log in again with an 2-factor authentication token:",
      "title": "2-Factor Authentication Required"
    },
    "403": {
      "body": "Unfortunately, you do not have access to this page.",
      "title": "Access denied"
    },
    "404": {
      "body": "The requested page could not be found.",
      "title": "Page not found"
    }
  },
  "gotosignmeup": "Go to the event offerings",
  "login": {
    "error": "A general error occurred during login, please contact support.",
    "forgotpassword": "Forgot your password?",
    "gotoregistration": "Register here",
    "intro": "Here you can log in as an external, registered user.",
    "invalidcredentials": "The entered username or password is invalid",
    "locked": "The specified account is currently locked. \nPlease contact support.",
    "notconfirmed": "The email address of the specified account has not yet been verified. \nLogin is only possible after confirmation.",
    "notregistered": "Not registered yet?",
    "success": "Registration Successful",
    "title": "Registration for external users"
  },
  "logout": {
    "fullylogout": "Log out completely",
    "fullylogouttext": "You have successfully logged out of the Campus+ Portal, but are still logged in to the KIT-wide Single Sign On service. Do you want to log out there as well?"
  },
  "notloggedin": "Not logged in",
  "notloggedtext": "You are not logged in or your session has expired.",
  "passwordreset": {
    "backtologin": "Back to login",
    "errors": {
      "EmailInvalid": "The specified email address is invalid",
      "InvalidToken": "The password reset token is invalid or has already been used.",
      "PasswordNotEmpty": "The password must not be empty",
      "PasswordRequiresDigit": "The password must contain at least one digit",
      "PasswordRequiresLower": "The password must contain at least one lowercase letter",
      "PasswordRequiresUpper": "The password must contain at least one uppercase letter",
      "PasswordTooShort": "The password must be at least 6 characters long"
    },
    "form": "Set new password",
    "intro": "Do you want to reset your password for your external account? Please note: This form is exclusively for externally registered accounts and cannot be used for KIT accounts.",
    "performintro": "On this page you can reset your password if you have forgotten the previous one.",
    "requestdetails": "If the email address you provided exists in our user database, you will shortly receive an email with instructions on how to reset your password.",
    "requestsent": "Your password reset request has been successfully submitted to us.",
    "requestsuccess": "Reset password requested",
    "submit": "Reset password",
    "success": "You have successfully changed your password and can now log in with the new password.",
    "title": "Reset password for external accounts"
  },
  "pleaselogintoaccess": "Please log in to access this page.",
  "register": {
    "account": "Account",
    "buttons": {
      "gotologin": "Go to login",
      "register": "Register"
    },
    "errors": {
      "DuplicateEmail": "The email address is already in use",
      "DuplicateUserName": "The username is already taken",
      "EmailInvalid": "The given E-Mail Adress is invalid",
      "EmailNotEmpty": "The email address cannot be empty",
      "PasswordNotEmpty": "The password cannot be empty",
      "PasswordRequiresDigit": "The password must contain at least one digit",
      "PasswordRequiresLower": "The password must contain at least one lowercase letter",
      "PasswordRequiresUpper": "The password must contain at least one capital letter",
      "PasswordTooShort": "The password must be at least 6 characters long",
      "RecaptchaInvalid": "reCAPTCHA validation failed",
      "RecaptchaUntrusted": "reCAPTCHA validation failed"
    },
    "failed": "Registration failed: ",
    "fields": {
      "currentunit": "Current institution (university or company) (optional)",
      "email": "E-mail address",
      "firstname": "First name",
      "lastname": "Surname",
      "password": "Password",
      "plannedcourse": "Planned course at KIT (optional)",
      "plannedcoursetooltip": "Registration for some events is limited to courses of study at KIT. \nIf you are in the process of switching to KIT, but do not yet have access to KIT, you can still register for these events by selecting the planned course of study."
    },
    "intro": "You can register as an external user in Campus to gain access to some of the functionalities (e.g. SignMeUp registrations).",
    "iuk": "Regulation for digital information processing and communication (IuK)",
    "iuktext": "I agree that my usage of this platform is in compliance with the {0}.",
    "kitlogin": "Login with KIT account",
    "kitusernotice": "Members of KIT (employees, students, partners) should login via {0} instead.",
    "profile": "Profile",
    "success": "You have successfully registered! \nPlease check your email to confirm your registration.",
    "title": "Registration for external users"
  },
  "start": {
    "changes": {
      "allchangestext": "You can find a list of all changes in the",
      "versinfo": "Version informations"
    },
    "labels": {
      "newsletter": "Newsletter"
    },
    "login": {
      "loginExternal": "Login (External users)",
      "loginKIT": "Login (KIT)",
      "register": "Registration (external users)",
      "requiredText": "Most features in this portal required a login."
    },
    "newsletter": {
      "body": "If you would like to be informed regularly about new functions and important changes, we would be pleased if you subscribe to our {0}.",
      "name": "Campus+ mailing list"
    },
    "welcome": {
      "body": "In this portal we would like to offer you innovative functions related to studies and teaching. \nIn many cases, the functions expand the possibilities offered by the Campus Management System (CAS) or application system (BZM). \nThe portal started in November 2021 with the BZM module with many features related to application management. \nIn August 2022, the new feature \"Registration Procedure\" was added.",
      "heading": "Welcome to the Campus Plus Portal!",
      "label": "Welcome"
    }
  },
  "tour": {
    "tooltip": "By clicking on this button you get a small tour of the functions of the current page."
  },
  "usefullwidth": "Use the full width of the browser",
  "usersettings": {
    "errors": {
      "tokencreate": "An error occurred while creating the token. Make sure that you are still logged in."
    },
    "intro": "On this page you can make settings to your user account in the future.<br />Currently this page is limited to creating API access tokens for authorized persons.",
    "title": "Settings",
    "tokencopied": "The token was copied to the clipboard",
    "tokencopy": "Copy token to clipboard",
    "tokencreate": "Create new token",
    "tokencreateconfirm": "Please note: A token is equivalent to your user account for use in the Campus+ portal. You are fully responsible for all actions performed via a token generated by you. If you pass on the token, a third party can perform actions in the portal on your behalf (and under your responsibility). ",
    "tokencreated": "A new token was created",
    "tokendelete": "Delete token",
    "tokenintro": "On this page you can create access tokens to access our public API. With these you authenticate yourself to the portal for programmatic access.<br /> <strong>Attention!</strong> The tokens have the same access rights to many areas as you do! If you give the token to another person, they will be able to perform actions on your behalf.",
    "tokenlist": "Your API tokens"
  }
}
