{
  "labels": {
    "procedure": "Studienangebot"
  },
  "menu": {
    "labels": {
      "dossierdownloader": "Sammelakten-Download",
      "overview": "Übersicht",
      "procedures": "Studienangebote"
    }
  },
  "notifications": {
    "infoletter": {
      "delete": {
        "success": "Der Infobrief für den Status {state} wurde gelöscht.",
        "text": "Möchten Sie den Infobrief für den Status \"{state}\" wirklich löschen? ",
        "title": "Infobrief löschen"
      },
      "upload": {
        "success": "Der Infobrief für den Status {state} wurde erfolgreich hochgeladen.",
        "text": "Hier können Sie einen neuen Infobrief für den genannten Status hinterlegen. Bitte beachten Sie, dass Sie inhaltliche Änderungen zuvor mit Frau Kurz (SLE) abstimmen sollten. Änderungen an Datumsangaben, Kontaktpersonen oder Links müssen nicht abgesprochen werden.",
        "title": "Infobrief hinterlegen für Status \"{state}\""
      }
    }
  }
}
