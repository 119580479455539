<template>
  <svg :width="size + 'px'" :height="size + 'px'" viewBox="0 0 42 42" class="donut">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M21,14 V28 M14,21 H28"
      stroke="#00a2ff"
      stroke-width="4"
    />
    <g class="donut-segments">
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="16"
        fill="transparent"
        stroke="#ffcc00"
        stroke-width="7"
        stroke-dasharray="27 73"
        stroke-dashoffset="37"
      ></circle>
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="16"
        fill="transparent"
        stroke="#ff9933"
        stroke-width="7"
        stroke-dasharray="27 73"
        stroke-dashoffset="64"
      ></circle>
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="16"
        fill="transparent"
        stroke="#6ec71d"
        stroke-width="7"
        stroke-dasharray="27 73"
        stroke-dashoffset="91"
      ></circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CampusPlusLogo",
  props: {
    size: {
      type: String,
      default: "32"
    }
  }
}
</script>
