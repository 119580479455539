<template>
  <q-card class="glasscard" elevation="2">
    <q-expansion-item v-model="open" :icon="icon" :label="caption" :class="color" header-class="text-h6">
      <q-card-section>
        <slot></slot>
      </q-card-section>
    </q-expansion-item>
  </q-card>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  color: { type: String, default: "secondary" },
  caption: { type: String },
  icon: { type: String },
  initOpen: { type: Boolean, default: false },
});
const open = ref(props.initOpen);
</script>

<style lang="scss">
.has-background .q-card.glasscard {
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.9);
}

.body--dark .has-background .q-card.glasscard {
  background-color: rgba(38, 50, 56, 0.75);
}

.glasscard {
  .v-label {
    font-size: 0.8rem;
  }

  body.screen--xs & .q-card__section--vert {
    padding: 15px 5px;
  }
}
</style>
