import axios from "axios";
axios.defaults.baseURL = "/api/";

export default {
  async getTerms() {
    const response = await axios.get("bzmplus/meta/terms");
    return response.data;
  },
  async getMetadata() {
    const response = await axios.get("bzmplus/meta");
    return response.data;
  },
  async getAllApplications(termGuid) {
    const response = await axios.get("bzmplus/applications?termGuid=" + termGuid);
    return response.data;
  },
  async getMultiProcedureApplications(procedureGuids) {
    const response = await axios.post("bzmplus/procedures/applications", { procedureGuids });
    return response.data;
  },
  async getMultiProcedureStats(procedureGuids) {
    const response = await axios.post("bzmplus/procedures/stats", { procedureGuids });
    return response.data;
  },
  async createProcedureGroup(termGuid, procedureGuids) {
    const response = await axios.post("bzmplus/groups", { termGuid, procedureGuids });
    return response.data;
  },
  async getProcedureGroup(id) {
    const response = await axios.get("bzmplus/groups/" + id);
    return response.data;
  },
  async getProcedureGroups(termGuid) {
    const response = await axios.get("bzmplus/groups/?termGuid=" + termGuid);
    return response.data;
  },
  async getProcedureGroupApplications(id) {
    const response = await axios.get("bzmplus/groups/" + id + "/applications");
    return response.data;
  },
  async getProcedureGroupStats(id) {
    const response = await axios.get("bzmplus/groups/" + id + "/stats");
    return response.data;
  },
  async updateProcedureGroupName(id, name) {
    const response = await axios.put("bzmplus/groups/" + id, { name });
    return response.data;
  },
  async deleteProcedureGroup(id) {
    const response = await axios.delete("bzmplus/groups/" + id);
    return response.data;
  },
  async getProcedures(termGuid) {
    const response = await axios.get("bzmplus/procedures?termGuid=" + termGuid);
    return response.data;
  },
  async getProcedureDetails(id) {
    const response = await axios.get("bzmplus/procedures/" + id);
    return response.data;
  },
  async getApplication(id) {
    const response = await axios.get("bzmplus/applications/" + id);
    return response.data;
  },
  async getProcedureDashboardData(id) {
    const response = await axios.get("bzmplus/procedures/" + id + "/dashboard");
    return response.data;
  },
  async getProcedureApplications(id) {
    const response = await axios.get("bzmplus/procedures/" + id + "/applications");
    return response.data;
  },
  async getStatisticsSankeyData() {
    const response = await axios.get("bzmplus/statistics/sankeydata");
    return response.data;
  },
  async validateDossierLink(link) {
    const response = await axios.post("bzmplus/documents/validate", { link });
    return response.data;
  },
  async getImportJob(cdapGuid, importGuid) {
    const response = await axios.get("bzmplus/procedures/" + cdapGuid + "/import/" + importGuid);
    return response.data;
  },
  async discardImport(cdapGuid, importGuid) {
    const response = await axios.delete("bzmplus/procedures/" + cdapGuid + "/import/" + importGuid);
    return response.data;
  },
  async startImport(cdapGuid, importGuid) {
    const response = await axios.post("bzmplus/procedures/" + cdapGuid + "/import/" + importGuid + "/start");
    return response.data;
  },
  async uploadFile(id, formData) {
    const result = await axios.post("bzmplus/procedures/" + id + "/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  },
  async deleteInfoLetter(id, state) {
    const response = await axios.delete("bzmplus/procedures/" + id + "/infoletter/" + state);
    return response.data;
  },
  async uploadInfoLetter(id, state, formData) {
    const result = await axios.post("bzmplus/procedures/" + id + "/infoletter/" + state, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  },
  async searchApplications(query) {
    const response = await axios.get("bzmplus/applications/search?query=" + query);
    return response.data;
  },
};
