import { createRouter, createWebHistory, RouterView } from "vue-router";

import Home from "@/views/Home.vue";

import useUserProfile from "../composables/useUserProfile";
import bzmRoutes from "./routes_bzmplus";
import signMeUpRoutes from "./routes_signmeup";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Start", breadCrumb: "Start" },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: { title: "Registrieren", breadCrumb: "Registrieren" },
  },
  {
    path: "/confirm",
    name: "Confirm",
    props: (route) => ({ userId: route.query.userId, code: route.query.code }),
    component: () => import("@/views/Confirm.vue"),
    meta: { title: "E-Mail-Adresse bestätigen", breadCrumb: "E-Mail-Adresse bestätigen" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { title: "Anmeldung (Externe)", breadCrumb: "Anmeldung (Externe)" },
  },
  {
    path: "/forgot-password",
    name: "PasswordResetRequest",
    component: () => import("@/views/PasswordResetRequest.vue"),
    meta: { title: "Passwort vergessen?", breadCrumb: "Password vergessen" },
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    props: (route) => ({ email: route.query.email, code: route.query.code }),
    component: () => import("@/views/PasswordReset.vue"),
    meta: { title: "Passwort zurücksetzen", breadCrumb: "Password zurücksetzen" },
  },
  {
    path: "/settings",
    name: "UserSettings",
    component: () => import("@/views/UserSettings.vue"),
    meta: { title: "Benutzereinstellungen", breadCrumb: "Benutzereinstellungen" },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
    meta: { title: "Kontakt", breadCrumb: "Kontakt" },
  },

  {
    path: "/error",
    name: "Error",
    component: RouterView,
    children: [
      {
        path: "401",
        name: "ErrorPage401",
        component: () => import("@/views/ErrorPage401.vue"),
      },
      {
        path: "401token",
        name: "ErrorPage401Token",
        component: () => import("@/views/ErrorPage401Token.vue"),
      },
      {
        path: "403",
        name: "ErrorPage403",
        component: () => import("@/views/ErrorPage403.vue"),
      },
    ],
  },
  {
    path: "/samples",
    component: RouterView,
    children: [
      {
        path: "containercard",
        name: "SamplesContainerCard",
        component: () => import("@/samples/ContainerCard/Demo.vue"),
      },
    ],
  },
  // {
  //   path: "/caldemo",
  //   component: () => import("@/views/CalDemo.vue"),
  // },
  // Routen für BZM+, liegen alle unter /bzmplus
  ...bzmRoutes,
  ...signMeUpRoutes,
  {
    path: "/:catchAll(.*)*",
    component: () => import("@/views/ErrorPage404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0 }), 300);
      })
    );
  },
});

const { isLoggedIn, userHasRole } = useUserProfile();

router.beforeEach((to, from) => {
  if (to.meta.requiresRole) {
    if (!isLoggedIn.value) {
      return { name: "ErrorPage401", query: { redirect: encodeURIComponent(to.fullPath) } };
    }
    if (!userHasRole(to.meta.requiresRole)) {
      return { name: "ErrorPage403", query: { redirect: from.fullPath } };
    }
  }
  if (to.meta.requiresLoggedIn) {
    if (!isLoggedIn.value) {
      return { name: "ErrorPage401", query: { redirect: to.fullPath } };
    }
  }
  if (to.meta.requires2FA) {
    if (!userHasRole("tokenLogin")) {
      return { name: "ErrorPage401Token", query: { redirect: from.fullPath } };
    }
  }
});

export default router;
