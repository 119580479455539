import { computed,ref } from "vue";

import * as Sentry from "@sentry/browser";

import userService from "../services/userService";

const userProfile = ref(null);
const isLoggedIn = computed(() => userProfile.value != null);
const loading = ref(true);
const drawer = ref(null);

export default function useUserProfile() {
  async function fetchProfile() {
    return userService.getInfo().then((data) => {
      userProfile.value = data;
      if (data) {
        Sentry.setUser({ username: data.userName, email: data.mail });
      } else {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
      loading.value = false;
    });
  }
  function logout() {
    userProfile.value = null;
    return userService.logout();
  }

  function userHasRole(role) {
    if (userProfile.value == null) return false;

    if (Array.isArray(role)) return userProfile.value.roles.filter((r) => role.includes(r)).length > 0;
    else return userProfile.value.roles.includes(role);
  }

  const toggleDrawer = () => (drawer.value = !drawer.value);

  return {
    userProfile,
    isLoggedIn,
    userHasRole,
    fetchProfile,
    logout,
    drawer,
    toggleDrawer,
  };
}
