import { defineComponent, h,ref } from "vue";

const renderConfirm = (resolve) => {
  return defineComponent({
    props: {
      title: {
        type: String,
      },
      text: {
        type: String,
        required: true,
      },
      yesText: {
        type: String,
        required: true,
      },
      noText: {
        type: String,
        required: true,
      },
      dangerous: { type: Boolean, default: false },
    },
    setup() {
      const isActive = ref(true);
      const isDark = ref(true);
      const confirm = () => {
        close();
        resolve(true);
      };
      const discard = () => {
        close();
        resolve(false);
      };
      const close = () => {
        isActive.value = false;
      };

      return {
        isActive,
        isDark,
        confirm,
        discard,
        close,
      };
    },
    render() {
      if (this.isActive) {
        return h(
          "div",
          {
            class: "v-overlay v-overlay--active v-dialog v-overlay--scroll-blocked " + (this.isDark ? "v-theme--dark" : "v-theme--light"),
          },
          [
            h("div", {
              class: "v-overlay__scrim",
              onClick: () => this.close(),
            }),
            h(
              "div",
              {
                class: "v-overlay__content",
              },
              [
                h(
                  "div",
                  {
                    class: "v-card v-card--density-density-default v-card--variant-contained",
                  },
                  [
                    h("div", { class: "v-card__underlay" }),
                    !!this.title &&
                      h(
                        "div",
                        {
                          class: "v-card-title",
                        },
                        this.title
                      ),
                    h(
                      "div",
                      {
                        class: "v-card-text",
                      },
                      this.text
                    ),
                    h(
                      "div",
                      {
                        class: "v-card-actions",
                        style: "display: flex; justify-content: flex-end;",
                      },
                      [
                        h(
                          "button",
                          {
                            class:
                              "v-btn v-btn--elevated v-btn--density-default v-btn--size-default v-btn--variant-contained " +
                              (this.dangerous ? "bg-red" : "bg-primary"),
                            onClick: () => this.confirm(),
                          },
                          this.yesText
                        ),
                        h(
                          "button",
                          {
                            class:
                              "v-btn v-btn--elevated bg-grey-darken-1 v-btn--density-default v-btn--size-default v-btn--variant-contained ml-4",
                            onClick: () => this.discard(),
                          },
                          this.noText
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        );
      }
      return null;
    },
  });
};

export { renderConfirm };
