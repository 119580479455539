import { createI18n } from "vue-i18n";

import datetimeFormats from "./datetimeformats";
import bzmplusDE from "./de/bzmplus.json";
import commonDE from "./de/common.json";
import portalDE from "./de/portal.json";
import signmeupDE from "./de/signmeup.json";
import bzmplusEN from "./en/bzmplus.json";
import commonEN from "./en/common.json";
import portalEN from "./en/portal.json";
import signmeupEN from "./en/signmeup.json";

const i18n = createI18n({
  legacy: false,
  silentFallbackWarn: false,
  silentTranslationWarn: false,
  locale: "de",
  messages: {
    de: {
      common: commonDE,
      signmeup: signmeupDE,
      portal: portalDE,
      bzmplus: bzmplusDE,
    },
    en: {
      common: commonEN,
      signmeup: signmeupEN,
      portal: portalEN,
      bzmplus: bzmplusEN,
    },
  },
  datetimeFormats,
});
const setLocale = (locale) => {
  i18n.global.locale = locale;
};

export { i18n, setLocale };
