<template>
  <q-page>
    <q-img src="/assets/kit_bg_banner.jpg" style="min-height: 80px; max-height: 100px" alt="KIT Hintergrundbild">
      <div class="absolute-bottom content-wrap flex justify-between items-end">
        <h4 class="text-white text-bold" style="text-shadow: 0 0 8px rgb(0 0 0)">
          {{ title }}
        </h4>
        <BzmGlobalSearch v-if="route.path.startsWith('/bzmplus')" />
      </div>
    </q-img>

    <div class="content-wrap" :class="{ 'full-width': preferences.settings.fullWidthLayout }">
      <slot />
    </div>
  </q-page>
</template>

<script setup>
import { useRoute } from "vue-router";
import { usePreferencesStore } from "@/stores/preferences.js";
import { useTitle } from "@vueuse/core";

import BzmGlobalSearch from "../features/bzmplus/components/shared/BzmGlobalSearch.vue";
const title = useTitle("Campus+ Portal", { observe: true });
const route = useRoute();
const preferences = usePreferencesStore();

</script>
