import { defineStore } from "pinia";
import { Dark } from "quasar";

import { useStorage } from "@vueuse/core";

export const usePreferencesStore = defineStore({
  id: "preferences",
  state: () => ({
    settings: useStorage("settings", {
      darkMode: true,
      background: "",
      demoMode: false,
      theme: "KIT",
      paging: 20,
      pagingGroup: 20,
      locale: "de",
      fullWidthLayout: false,
    }),
  }),
  getters: {
    isDarkMode() {
      return this.settings.darkMode;
    },
    isDemoMode() {
      return this.settings.demoMode;
    },
  },
  actions: {
    toggleDarkMode() {
      this.settings.darkMode = !this.settings.darkMode;
      Dark.toggle();
      this.settings.background = null;
    },
    setDarkMode(value) {
      this.settings.darkMode = value;
      Dark.set(value);
      this.settings.background = null;
    },
    setFullWidthLayout(value) {
      this.settings.fullWidthLayout = value;
    },
    toggleDemoMode() {
      this.settings.demoMode = !this.settings.demoMode;
    },
    setBackground(background) {
      this.settings.background = background;
    },
    setTheme(theme) {
      this.settings.theme = theme || "KIT";
    },
    setPaging(number) {
      this.settings.paging = number;
    },
    setPagingGroup(number) {
      this.settings.pagingGroup = number;
    },
    setLocale(locale) {
      this.settings.locale = locale;
    },
  },
});
