import { createApp, inject, nextTick } from "vue";

import { renderConfirm } from "./confirm";

const confirmKey = "confirm";

const plugin = (options) => {
  return {
    show: (question, title, dangerous) => {
      const rootID = !options?.root ? "#app" : options.root;
      return new Promise((resolve) => {
        const yesText = options?.yesText ? options.yesText : "Yes";
        const noText = options?.noText ? options.noText : "No";

        const comp = renderConfirm(resolve);
        console.log(comp);
        nextTick(() => {
          return createApp(comp, {
            text: question,
            title: title,
            yesText: yesText,
            noText: noText,
            dangerous: !!dangerous,
          }).mount(rootID);
        });
      });
    },
  };
};

const VueConfirmPlugin = (App, options) => {
  const inter = plugin(options);
  App.provide(confirmKey, inter);
};

const useConfirm = (customKey = "") => {
  return inject(customKey !== "" ? customKey : confirmKey);
};

export default VueConfirmPlugin;

export { useConfirm };
