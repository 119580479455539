// FILE: main.js

import { createApp } from "vue";
import { createPinia } from "pinia";
import { AddressbarColor, Dialog, Loading, Notify, Quasar } from "quasar";
//import quasarIconSet from "quasar/icon-set/svg-material-icons";
import quasarIconSet from "quasar/icon-set/svg-material-symbols-outlined";
import quasarLang from "quasar/lang/de";

import VueConfirmPlugin from "@/components/confirm";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import * as Sentry from "@sentry/vue";

import "@quasar/extras/roboto-font/roboto-font.css";

// Import icon libraries
//import "@quasar/extras/material-symbols-outlined/material-symbols-outlined.css";
// Import Quasar css
import "quasar/src/css/index.sass";

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from "./App.vue";
import { i18n } from "./i18n";
import filters from "./plugins/filters";
import router from "./router";

const myApp = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app: myApp,
    dsn: "https://5ba5a1a608f7424abc3d9a462b8e0e90@glitchtip.isl.scc.kit.edu/1",
    integrations: [],
    environment: import.meta.env.PROD ? "production" : "development",
    // eslint-disable-next-line no-undef
    release: __APP_VERSION__,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    logErrors: true,
    ignoreErrors: [
      "Unable to preload CSS",
      "Importing a module script failed.",
      "Load failed",
      "Network Error",
      "error loading dynamically imported module",
    ],
    beforeSend: (event, hint) => {
      // see all errors, what you wants.
      // using console.log(hint.originalException)

      // for example, not send when error code 404 when using axios
      if (hint && typeof hint.originalException === "object") {
        if (
          hint.originalException?.response &&
          hint.originalException?.response.status &&
          hint.originalException?.response.status === 401
        ) {
          return null;
        }
      }
      return event;
    },
    // beforeSend(event, hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id });
    //   }
    //   return event;
    // },
  });
}
myApp.config.globalProperties.$filters = filters;

import QListItemBoolean from "./components/base/QListItemBoolean.vue";
import QListItemCaption from "./components/base/QListItemCaption.vue";
import QListItemSide from "./components/base/QListItemSide.vue";
import GlassCard from "./components/shared/GlassCard.vue";
import MiniCard from "./components/shared/MiniCard.vue";
import MiniGlassCard from "./components/shared/MiniGlassCard.vue";
import ToggleCard from "./components/shared/ToggleCard.vue";

myApp.component("QListItemCaption", QListItemCaption);
myApp.component("QListItemBoolean", QListItemBoolean);
myApp.component("QListItemSide", QListItemSide);
myApp.component("GlassCard", GlassCard);
myApp.component("ToggleCard", ToggleCard);
myApp.component("MiniGlassCard", MiniGlassCard);
myApp.component("MiniCard", MiniCard);

myApp.use(autoAnimatePlugin);

import useUserProfile from "./composables/useUserProfile";

let { fetchProfile } = useUserProfile();
fetchProfile().then(() => {
  myApp
    .use(createPinia())
    .use(router)
    .use(i18n)
    .use(Quasar, {
      plugins: { Dialog, Notify, Loading, AddressbarColor }, // import Quasar plugins and add here
      lang: quasarLang,
      iconSet: quasarIconSet,
      config: {
        brand: {
          // primary: "#4664AA",
          // ...
        },
        screen: {
          bodyClasses: true, // <<< add this
        },
      },
    })
    .use(VueConfirmPlugin, {
      root: "#confirm",
      yesText: "Ja",
      noText: "Nein",
    })
    .mount("#app");
});

function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
Date.prototype.toISOStringShort = function () {
  return (
    this.getUTCFullYear() +
    "-" +
    pad(this.getUTCMonth() + 1) +
    "-" +
    pad(this.getUTCDate()) +
    "T" +
    pad(this.getUTCHours()) +
    ":" +
    pad(this.getUTCMinutes()) +
    ":" +
    pad(this.getUTCSeconds()) +
    "Z"
  );
};
