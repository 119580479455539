{
  "abbreviations": {
    "fs": "FS"
  },
  "degrees": {
    "11": "Diplom",
    "38": "LA Bachelor",
    "47": "LA Bachelor",
    "64": "LA Master",
    "66": "LA Master",
    "82": "Bachelor",
    "88": "Master",
    "97": "Zeitstudium",
    "ISL": "ISL-Test",
    "TEST": "ISL-Test"
  },
  "errors": {
    "invalidMail": "Die angegebene E-Mail-Adresse ist ungültig",
    "invalidPasswordComplexity": "Das Passwort sollte mindestens eine Nummer sowie einen Groß- und einen Kleinbuchstaben enthalten und 6 Zeichgen lang sein.",
    "nonKitOnly": "Eine Registrierung mit KIT-Adressen ist nicht möglich. KIT-Angehörige sollten die KIT-Anmeldung nutzen.",
    "passwordsDoNotMatch": "Die angegebenen Passwörter stimmen nicht überein"
  },
  "labels": {
    "action": "Aktion",
    "add": "Hinzufügen",
    "additionalactions": "Weitere Aktionen",
    "all": "Alle",
    "apply": "Anwenden",
    "begin": "Beginn",
    "cancel": "Abbrechen",
    "changes": "Neuerungen",
    "close": "Schließen",
    "confirm": "Bestätigen",
    "contact": "Kontakt",
    "copy": "Kopieren",
    "create": "Anlegen",
    "delete": "Löschen",
    "discard": "Verwerfen",
    "discardchanges": "Änderungen verwerfen",
    "documentation": "Dokumentation",
    "duplicate": "Duplizieren",
    "edit": "Bearbeiten",
    "end": "Ende",
    "help": "Hilfe",
    "home": "Startseite",
    "merge": "Zusammenführen",
    "optimizecontinue": "Verteilung fortsetzen",
    "optimizenew": "Neue Verteilung",
    "order": "Reihenfolge",
    "pleaseselect": "Bitte auswählen",
    "publish": "Veröffentlichen",
    "publishchanges": "Änderungen veröffentlichen",
    "regarding": "betrifft",
    "save": "Speichern",
    "search": "Suchen",
    "sendmessage": "Nachricht senden",
    "settings": "Einstellungen",
    "signin": "Anmelden",
    "signout": "Abmelden",
    "signup": "Anmelden",
    "switchlanguage": "Switch to English",
    "update": "Aktualisieren",
    "yourchoice": "Ihre Auswahl"
  },
  "notifications": {
    "success": "Die Änderungen wurden erfolgreich durchgeführt."
  },
  "terms": {
    "additem": "Eintrag hinzufügen",
    "additionalsettings": "Weitere Einstellungen",
    "addperson": "Person hinzufügen",
    "apply": "Übernehmen",
    "close": "Schließen",
    "course": "Kurs",
    "date": "Datum",
    "description": "Beschreibung",
    "difference": "Abweichung",
    "display": "Darstellung",
    "export": "Exportieren",
    "external": "Extern",
    "externaluser": "Externer Nutzer",
    "externalusers": "Externe Nutzer",
    "item": "Eintrag",
    "items": "Einträgen",
    "kitmember": "KIT-Angehörige/r",
    "label": "Bezeichnung",
    "language": "Sprache",
    "login": "Anmelden",
    "logout": "Abmelden",
    "matriculationId": "Matrikelnummer",
    "members": "Mitglieder",
    "message": "Nachricht",
    "new": "neu",
    "no": "Nein",
    "none_female": "keine",
    "none_male": "keiner",
    "noresults": "Keine Ergebnisse",
    "note": "Hinweis",
    "object": "Objekt",
    "of": "von",
    "or": "oder",
    "participants": "Teilnehmer",
    "recipient": "Empfänger",
    "recipients": "Empfänger",
    "register": "Registrieren",
    "requiredField": "Pflichtangabe",
    "result": "Ergebnis",
    "send": "Senden",
    "subject": "Betreff",
    "term": "Semester",
    "unlimited": "unbegrenzt",
    "unsaved": "ungespeichert",
    "yes": "Ja"
  },
  "time": {
    "ended": "beendet",
    "in": "beginnt in {time}",
    "left": "noch {time}",
    "unlimited": "unbegrenzt"
  },
  "words": {
    "by": "durch",
    "persons": "Personen",
    "personssolo": "Personen"
  }
}
