export default {
  formatDate(value, hideEmpty) {
    if (value) {
      var d = new Date(value);
      return d.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    } else return hideEmpty ? "" : "-";
  },
  formatDateTime(value) {
    if (value) {
      var d = new Date(value);
      return d.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else return "-";
  },
  timeDiffSeconds(startDate, endDate) {
    if (!startDate || !endDate) return "-";
    var diff = new Date(endDate) - new Date(startDate);
    return Math.round(diff / 1000) + " Sekunden";
  },

  timeBetween(start, end) {
    var delta, days, hours, minutes;

    delta = Math.abs(end - start) / 1000;
    days = Math.floor(delta / 86400);
    delta -= days * 86400;
    hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    minutes = Math.floor(delta / 60) % 60;

    if (days > 0) return days + "d " + hours + "h ";
    else if (hours > 0) return hours + "h " + minutes + "m";
    else return minutes + "m";
  },

  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + " " + units[u];
  },
  capitalize(data) {
    capitalized = [];
    data.split(" ").forEach((word) => {
      capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    });
    return capitalized.join(" ");
  },
};
